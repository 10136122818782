<template>
     <div class="initiate_contract">
          <div class="inquiry_header">
               <div class="header_bg" :style="{backgroundImage:`url(${header_bg})`}"></div>
          </div>

          <div class="initiate_contract_z">
               <div class="init_main">
                    <div class="box1 flex-col">
                         <div class="block1 flex-col">
                              <div class="group1 flex-col">
                                   <span class="info1">收货地址</span>
                              </div>
                              <div class="group2g flex-row">
                                   <div class="main1 flex_row_start_center">
                                        <span class="word2">*</span>
                                        <span class="info2">收货人：</span>
                                        <div :class="{validate:validate.receiverName}">
                                             <div class="main2 flex-col vBorder">
                                                  <input type="text" v-model="receiverName">
                                             </div>
                                             <span class="error_msg">{{validate.receiverName}}</span>
                                        </div>

                                   </div>
                                   <div class="main1 flex_row_start_center" style="margin-left: 30px;">
                                        <span class="word2">*</span>
                                        <span class="info2">手机号：</span>
                                        <div :class="{validate:validate.receiverMobile}">
                                             <div class="main2 flex-col vBorder">
                                                  <input type="text" v-model="receiverMobile">
                                             </div>
                                             <span class="error_msg">{{validate.receiverMobile}}</span>
                                        </div>

                                   </div>
                              </div>
                              <div class="group3g flex-row">
                                   <div class="main1 flex_row_start_center">
                                        <span class="word2">*</span>
                                        <span class="info2">所在地区：</span>
                                        <div :class="{validate:validate.receiverAddress}">
                                             <div class="block3 flex-col vBorder">
                                                  <el-cascader ref="cascaderAddr" v-model="receiverAddress"
                                                       :options="areaDataArray" placeholder="请选择所在地区"
                                                       :props="cascaderProps" size="small" clearable
                                                       @change="inputEvent('receiverAddress')">
                                                  </el-cascader>
                                             </div>
                                             <span class="error_msg">{{validate.receiverAddress}}</span>
                                        </div>

                                   </div>
                              </div>
                              <div class="group4 flex-row">
                                   <div class="main1 flex_row_start_center">
                                        <span class="word2">*</span>
                                        <span class="info2">详细地址：</span>
                                        <div :class="{validate:validate.receiverAddressDetail}">
                                             <div class="box3 flex_row_start_center vBorder">
                                                  <input type="text" v-model="receiverAddressDetail">
                                                  <span class="word8g">{{receiverAddressDetail.length}}/50</span>
                                             </div>
                                             <span class="error_msg">{{validate.receiverAddressDetail}}</span>
                                        </div>

                                   </div>
                              </div>
                              <div class="group5 flex-col">
                                   <span class="word9">产品及费用清单</span>
                              </div>


                              <!-- 产品列表start -->
                              <div>
                                   <div class="group6 flex-col">
                                        <div class="mod1g flex-row">
                                             <span class="word10">序号</span>
                                             <span class="txt3">产品图片</span>
                                             <span class="txt4">产品名称</span>
                                             <span class="word11">规格/型号</span>
                                             <span class="word12">数量</span>
                                             <div class="group7">
                                                  <span class="word13g">单价(元)&nbsp;</span>
                                                  <span class="word14g">含税</span>
                                             </div>
                                             <span class="info6">税率</span>
                                             <span class="info7">小计</span>
                                        </div>
                                   </div>
                                   <div class="group8 flex-col" v-for="(pro,proIdx) in productList">
                                        <div class="section1 flex-row">
                                             <span class="word15">{{proIdx+1}}</span>
                                             <img class="pic1" :src="pro.productImage" alt="" v-if="pro.productImage">
                                             <img class="pic1" referrerpolicy="no-referrer"
                                                  src="@/assets/inquiry/inq_icon4.png" v-else />
                                             <span class="paragraph1">{{pro.productName||'--'}}</span>
                                             <span class="word16">{{pro.spec||'--'}}</span>
                                             <span class="word17">{{pro.buyNum}}{{pro.unitName}}</span>

                                             <div :class="{validate:validate.taxPrice.index==proIdx}">
                                                  <div class="mod2g flex-col vBorder">
                                                       <input type="text" v-model="pro.taxPrice">
                                                  </div>
                                                  <span class="error_msg">{{validate.taxPrice.msg}}</span>
                                             </div>

                                             <div :class="{validate:validate.taxRate.index==proIdx}">
                                                  <div class="mod3f flex-col vBorder">
                                                       <input type="text" v-model="pro.taxRate">
                                                  </div>
                                                  <span class="error_msg">{{validate.taxRate.msg}}</span>
                                             </div>

                                             <span class="word18">%</span>
                                             <span
                                                  class="word19">{{calToTaxed(pro.buyNum,pro.price,pro.taxRate,pro.taxType)}}</span>
                                        </div>
                                   </div>
                              </div>
                              <!-- 产品列表end -->

                              <!-- 费用列表start -->
                              <div class="group11 flex-col">
                                   <div class="mod4 flex-row">
                                        <span class="txt9">收费项</span>
                                        <div class="box4">
                                             <span class="word27">报价(元）</span>
                                             <span class="word28">含税</span>
                                        </div>
                                        <span class="word29">税率</span>
                                        <span class="word30">小计</span>
                                   </div>
                              </div>
                              <div class="group12 flex-col" v-for="(fee,feeIdx) in feeList" :key="feeIdx">
                                   <div class="box5 flex-row">
                                        <span class="word31">{{fee.feeName}}</span>
                                        <div :class="{validate:validate.feeTax.index==feeIdx}">
                                             <div class="main7 flex-col vBorder">
                                                  <input type="text" v-model="fee.feeTax">
                                             </div>
                                             <span class="error_msg">{{validate.feeTax.msg}}</span>
                                        </div>

                                        <div :class="{validate:validate.feeTaxRate.index==feeIdx}">
                                             <div class="main8 flex-col">
                                                  <input type="text" v-model="fee.taxRate">
                                             </div>
                                             <span class="error_msg">{{validate.feeTaxRate.msg}}</span>
                                        </div>


                                        <span class="word33">%</span>
                                        <span class="txt10">{{calToTaxed(1,fee.fee,fee.taxRate,fee.taxType)}}</span>
                                   </div>
                              </div>
                              <!-- 费用列表end -->


                              <div class="group13 flex-row justify-between">
                                   <div class="box6">
                                        <span class="txt11">合计金额：</span>
                                        <span class="txt12">¥{{calcateAmountTaxed}}</span>
                                   </div>
                                   <span class="word34">(含税：￥{{containTaxed.toFixed(2)}})</span>
                              </div>

                              <div class="group14 flex-row">
                                   <span class="word35">优惠金额：￥</span>

                                   <div class="block4 flex-col">
                                        <input type="text" v-model="discountAmount">
                                   </div>
                              </div>


                              <div class="group15 flex-row justify-between">
                                   <div class="block5">
                                        <span class="word36">合约金额：</span>
                                        <span class="txt14">¥{{finalAccount}}</span>
                                   </div>
                                   <span class="info15">(含税：￥{{containTaxed.toFixed(2)}})</span>
                              </div>


                              <!-- 交易合约信息start -->
                              <div class="group16 flex-col">
                                   <span class="word39">交易合约信息</span>
                              </div>
                              <div class="group17 flex-row justify-between">
                                   <div class="main9">
                                        <div class="flex_row_start_center con1">
                                             <span class="word2">*</span>
                                             <span class="infoBox3">合约接受截止时间：</span>
                                             <div :class="{validate:validate.endTime}">
                                                  <div class="group18 flex-col vBorder">
                                                       <div class="group181">
                                                            <el-date-picker v-model="endTime" type="date"
                                                                 placeholder="请选择" :disabled-date="disabledDate"
                                                                 :shortcuts="shortcuts" size="small"
                                                                 @change="inputEvent('endTime')">
                                                            </el-date-picker>
                                                       </div>
                                                  </div>
                                                  <span class="error_msg">{{validate.endTime}}</span>
                                             </div>

                                        </div>
                                        <div class="flex_row_start_center con1">
                                             <span class="infoBox3">买方：</span>
                                             <div class="group18 flex-col">
                                                  <span class="paragraph3">{{receiverName}}</span>
                                             </div>
                                        </div>
                                        <div class="flex_row_start_center con1">
                                             <span class="infoBox3">卖方：</span>
                                             <div class="group18 flex-col">
                                                  <span
                                                       class="paragraph3">{{conDTO.enterpriseName}}&nbsp;&nbsp;({{conDTO.contactName}}&nbsp;{{conDTO.contactMobile}})</span>
                                             </div>
                                        </div>
                                        <div class="flex_row_start_center con1">
                                             <span class="word2">*</span>
                                             <span class="infoBox3">最晚交付日期：</span>


                                             <div :class="{validate:validate.latestTime}">
                                                  <div class="group18 flex-col vBorder">
                                                       <div class="group181">
                                                            <el-date-picker v-model="latestDeliverDate" type="date"
                                                                 placeholder="请选择" :disabled-date="disabledDate"
                                                                 :shortcuts="shortcuts" size="small"
                                                                 @change="inputEvent('latestTime')">
                                                            </el-date-picker>
                                                       </div>
                                                  </div>
                                                  <span class="error_msg">{{validate.latestTime}}</span>
                                             </div>

                                        </div>


                                        <div class="flex_row_start_center con1">
                                             <span class="infoBox3">结算方式：</span>
                                             <div class="box8 flex_row_start_center">
                                                  <div class="flex_row_start_center con11"
                                                       @click="checkRadio('bill',1,'定金发货')">
                                                       <img class="icon1" src="@/assets/inquiry/inq_icon5.png"
                                                            v-if="billType==1" />
                                                       <div class="wrap2 flex-col" v-else></div>
                                                       <span class="info16">定金发货</span>
                                                  </div>

                                                  <div class="flex_row_start_center con11"
                                                       @click="checkRadio('bill',2,'全额付款')">
                                                       <img class="icon1" src="@/assets/inquiry/inq_icon5.png"
                                                            v-if="billType==2">
                                                       <div class="wrap2 flex-col" v-else></div>
                                                       <span class="info16">全额付款</span>
                                                  </div>

                                                  <div class="flex_row_start_center con11"
                                                       @click="checkRadio('bill',3,'分期付款')">
                                                       <img class="icon1" src="@/assets/inquiry/inq_icon5.png"
                                                            v-if="billType==3">
                                                       <div class="wrap2 flex-col" v-else></div>
                                                       <span class="info16">分期付款</span>
                                                  </div>

                                                  <div class="flex_row_start_center con11">

                                                       <div class="flex_row_start_center" @click="checkRadio('bill',4)">
                                                            <img class="icon1" src="@/assets/inquiry/inq_icon5.png"
                                                                 v-if="billType==4">
                                                            <div class="wrap2 flex-col" v-else></div>
                                                            <span class="info16">其他</span>
                                                       </div>

                                                       <div class="wrap5 flex-col">
                                                            <input type="text" v-model="billTypeName"
                                                                 :disabled="billType!=4" v-show="billType==4">
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>


                                        <div class="flex_row_start_center con1">
                                             <span class="infoBox3">发票类型：</span>
                                             <div class="box8 flex_row_start_center">
                                                  <div class="flex_row_start_center con11"
                                                       @click="checkRadio('invoice',1)">
                                                       <img class="icon1" src="@/assets/inquiry/inq_icon5.png"
                                                            v-if="invoiceType==1" />
                                                       <div class="wrap2 flex-col" v-else></div>
                                                       <span class="info16">无需发票</span>
                                                  </div>

                                                  <div class="flex_row_start_center con11"
                                                       @click="checkRadio('invoice',2)">
                                                       <img class="icon1" src="@/assets/inquiry/inq_icon5.png"
                                                            v-if="invoiceType==2" />
                                                       <div class="wrap2 flex-col" v-else></div>
                                                       <span class="info16">增值税专用发票</span>
                                                  </div>

                                                  <div class="flex_row_start_center con11"
                                                       @click="checkRadio('invoice',3)">
                                                       <img class="icon1" src="@/assets/inquiry/inq_icon5.png"
                                                            v-if="invoiceType==3" />
                                                       <div class="wrap2 flex-col" v-else></div>
                                                       <span class="info16">增值税普通发票</span>
                                                  </div>
                                             </div>
                                        </div>

                                        <div class="flex_row_start_center con1">
                                             <span class="infoBox3">配送方式：</span>
                                             <div class="box8 flex_row_start_center">
                                                  <div class="flex_row_start_center con11"
                                                       @click="checkRadio('deliver',1,'买方自提')">
                                                       <img class="icon1" src="@/assets/inquiry/inq_icon5.png"
                                                            v-if="deliverType==1" />
                                                       <div class="wrap2 flex-col" v-else></div>
                                                       <span class="info16">买方自提</span>
                                                  </div>

                                                  <div class="flex_row_start_center con11"
                                                       @click="checkRadio('deliver',2,'供方承运')">
                                                       <img class="icon1" src="@/assets/inquiry/inq_icon5.png"
                                                            v-if="deliverType==2" />
                                                       <div class="wrap2 flex-col" v-else></div>
                                                       <span class="info16">供方承运</span>
                                                  </div>

                                                  <div class="flex_row_start_center con11"
                                                       @click="checkRadio('deliver',3)">
                                                       <img class="icon1" src="@/assets/inquiry/inq_icon5.png"
                                                            v-if="deliverType==3" />
                                                       <div class="wrap2 flex-col" v-else></div>
                                                       <span class="info16">其他</span>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              </div>
                              <!-- 交易合约信息end -->


                              <div class="group21 flex-col">
                                   <span class="word44">其他约定</span>
                              </div>
                              <div class="group22 flex-row justify-between">
                                   <span class="word45">其他约定：</span>
                                   <div class="layer2 flex-col">
                                        <textarea cols="30" rows="10" v-model="notes"></textarea>
                                        <span class="word46">{{notes.length}}/500</span>
                                   </div>
                              </div>
                         </div>
                    </div>

                    <div class="box11 flex-col">
                         <div class="box12 flex-col" @click="initiate_contract">
                              <span class="txt17">{{contractId?'编辑合约':'发起合约'}}</span>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>

<script>
     import { ref, reactive, getCurrentInstance, onMounted, toRefs, computed } from 'vue'
     import { useRoute, useRouter } from 'vue-router'
     import { inqUtils } from '@/utils/inquiry_utils.js'
     import { ElMessage } from 'element-plus'
     export default {
          setup() {
               const header_bg = require('@/assets/inquiry/header_bg2.png')
               const { proxy } = getCurrentInstance()
               const value = ref('')

               const route = useRoute()
               const router = useRouter()

               const conDTO = reactive({
                    "billType": 1,
                    "billTypeName": "定金发货",
                    "contractId": 0,
                    "deliverType": 1,
                    "deliverTypeName": "买方自提",
                    "discountAmount": 0,
                    "endTime": "",
                    "feeList": [],
                    "invoiceType": 1,
                    "latestDeliverDate": "",
                    "notes": "",
                    "productList": [],
                    "quoteId": 0,
                    "receiverAddress": "",
                    "receiverAddressDetail": "",
                    "receiverMobile": "",
                    "receiverName": ""
               })
               const { areaDataArray, cascaderProps, formatTime, disabledDate, getPartNumber } = inqUtils();

               const validate = reactive({
                    receiverName: '',
                    receiverMobile: '',
                    receiverAddress: '',
                    receiverAddressDetail: '',
                    taxRate: { index: -1, msg: '' },
                    taxPrice: { index: -1, msg: '' },
                    feeTax: { index: -1, msg: '' },
                    feeTaxRate: { index: -1, msg: '' },
                    endTime: '',
                    latestTime: ''
               })



               const getQuoInfo = () => {
                    proxy.$get('v3/business/front/supplierInquire/quoteDetail', {
                         quoteId: route.query.quoteId
                    }).then(res => {
                         if (res.state == 200) {
                              conDTO.productList = res.data.productList
                              conDTO.feeList = res.data.feeList
                              conDTO.contactName = res.data.contactName
                         }
                    })
               }

               const checkRadio = (checkType, value, valueName) => {
                    switch (checkType) {
                         case 'deliver': {
                              conDTO.deliverType = value
                              conDTO.deliverTypeName = valueName ? valueName : ''
                              break
                         }
                         case 'bill': {
                              conDTO.billType = value
                              conDTO.billTypeName = valueName ? valueName : ''
                              break
                         }
                         case 'invoice': {
                              conDTO.invoiceType = value
                              break
                         }
                    }
               }

               const inputEvent = (val, index) => {
                    if (index != undefined) {
                         validate[val].index = -1
                         validate[val].msg = ''

                    } else {
                         validate[val] = ''
                    }
               }

               const checkInputEvent = () => {

                    let {
                         feeList,
                         productList,
                         receiverName,
                         receiverMobile,
                         receiverAddress,
                         receiverAddressDetail,
                         endTime,
                         latestDeliverDate
                    } = conDTO

                    if (!receiverName) {
                         validate.receiverName = '请输入收货人姓名'
                         return false
                    }
                    if (!receiverMobile) {
                         validate.receiverMobile = '请输入手机号'
                         return false
                    }

                    if (!receiverAddress.length) {
                         validate.receiverAddress = '请选择所在地区'
                         return false
                    }

                    if (!receiverAddressDetail) {
                         validate.receiverAddressDetail = '请填写详细地址'
                         return false
                    }

                    if (proxy.$checkPhone(receiverMobile) != true) {
                         validate.receiverMobile = '格式错误，请输入正确的手机号码'
                         return false
                    }

                    for (let i = 0; i < productList.length; i++) {

                         if (!productList[i].taxPrice || Number(productList[i].taxPrice) <= 0) {
                              validate.taxPrice.index = i
                              validate.taxPrice.msg = '请填写正确的单价'
                              return false
                         }

                         if (!productList[i].taxRate || Number(productList[i].taxRate) <= 0) {
                              validate.taxRate.index = i
                              validate.taxRate.msg = '请填写正确的税率'
                              return false
                         }
                    }

                    for (let y = 0; y < feeList.length; y++) {

                         if (!feeList[y].fee || Number(feeList[y].fee) <= 0) {
                              validate.fee.index = i
                              validate.fee.msg = '请输入正确报价'
                              return false
                         }

                         if (!feeList[y].taxRate || Number(feeList[y].taxRate) <= 0) {
                              validate.feeTaxRate.index = i
                              validate.feeTaxRate.msg = '请输入正确的税率'
                              return false
                         }
                    }


                    if (!endTime) {
                         validate.endTime = '请选择正确的合约接受截止时间'
                         return false
                    }

                    if (!latestDeliverDate) {
                         validate.latestTime = '请选择正确的最晚交付日期'
                         return false
                    }



                    return true
               }

               const initiate_contract = () => {
                    if (!checkInputEvent()) {
                         return
                    }

                    conDTO.latestDeliverDate = formatTime(conDTO.latestDeliverDate)
                    conDTO.endTime = formatTime(conDTO.endTime)
                    conDTO.receiverAddress = conDTO.receiverAddress.join('/')



                    let param = conDTO

                    param.productList.map(item => {
                         item.productImage = item.productImagePath
                         delete item.productImagePath
                    })

                    let url
                    if (route.query.from && route.query.contractId) {
                         url = 'v3/business/front/supplierInquire/updateContract'
                    } else {
                         url = 'v3/business/front/supplierInquire/contract'
                    }


                    proxy.$post(url, conDTO, 'json').then(res => {
                         if (res.state == 200) {
                              ElMessage.success(res.msg)
                              setTimeout(() => {
                                   router.push('/member/inquiry/myCon?tab=supplier')
                              }, 500)
                         } else {
                              ElMessage.error(res.msg)
                         }
                    })


               }


               const getConInfo = () => {
                    proxy.$get('v3/business/front/supplierInquire/contractDetail', {
                         contractId: route.query.contractId
                    }).then(res => {
                         if (res.state == 200) {
                              Object.keys(res.data).forEach(item => {
                                   if (item == 'receiverAddress') {
                                        conDTO[item] = res.data[item].split('/')
                                   } else {
                                        conDTO[item] = res.data[item]
                                   }
                              })

                         }
                    })
               }


               //计算属性----计算产品列表和其他费用的小计
               const calToTaxed = computed(() => {
                    return (buyNum, price, taxRate, taxType) => {
                         let p
                         let lTxRt = taxRate || 0
                         if (taxType == 1) {
                              p = (Number(buyNum) * Number(price))

                         } else {
                              let untaxedPrice = Number(price) + (Number(price) * (Number(lTxRt) / 100))
                              p = (buyNum * untaxedPrice)
                         }

                         return p > 0 ? p.toFixed(2) : 0

                    }
               })

               //函数---计算产品列表和其他费用的小计--用于总的含税价计算
               const calToTaxedFunc = (buyNum, price, taxRate, taxType) => {
                    let p
                    let lTxRt = taxRate || 0
                    if (taxType == 1) {
                         p = (Number(buyNum) * Number(price))

                    } else {
                         let untaxedPrice = Number(price) + (Number(price) * (Number(lTxRt) / 100))
                         p = (buyNum * untaxedPrice)
                    }
                    return Number(p) > 0 ? Number(p).toFixed(2) : 0
               }

               //函数---计算产品列表和其他费用的总未税价
               const calToUnTaxedFunc = (buyNum, price, taxRate, taxType) => {
                    let p
                    let lTxRt = taxRate || 0
                    if (taxType == 1) {
                         let taxedPrice = Number(price) / (1 + (Number(lTxRt) / 100))
                         p = (Number(buyNum) * Number(taxedPrice))

                    } else {
                         p = (Number(buyNum) * Number(price))
                    }
                    return Number(p) > 0 ? Number(p).toFixed(2) : 0
               }


               //计算属性----含税价总和
               const calcateAmountTaxed = computed(() => {
                    let totalPro = 0, totalFee = 0
                    if (conDTO.productList.length) {

                         conDTO.productList.forEach(item => {
                              totalPro += Number(calToTaxedFunc(item.buyNum, item.price, item.taxRate, item.taxType))
                         })
                    }


                    if (conDTO.feeList.length) {
                         conDTO.feeList.forEach(item1 => {
                              totalFee += Number(calToTaxedFunc(1, item1.fee, item1.taxRate, item1.taxType))
                         })
                    }


                    return Number(totalPro) + Number(totalFee)
               })

               //计算属性----未税价总和
               const calcateAmountUnTaxed = computed(() => {
                    let totalPro = 0
                    if (conDTO.productList.length) {
                         conDTO.productList.forEach(item => {
                              totalPro += Number(calToUnTaxedFunc(item.buyNum, item.price, item.taxRate, item.taxType))
                         })
                    }
                    let totalFee = 0
                    if (conDTO.feeList.length) {

                         conDTO.feeList.forEach(item1 => {
                              totalFee += Number(calToUnTaxedFunc(1, item1.fee, item1.taxRate, item1.taxType))
                         })
                    }


                    return Number(totalPro) + Number(totalFee)
               })


               const containTaxed = computed(() => {
                    console.log(calcateAmountTaxed.value, calcateAmountUnTaxed.value)
                    return Number(calcateAmountTaxed.value) - Number(calcateAmountUnTaxed.value)
               })


               const finalAccount = computed(() => {
                    return calcateAmountTaxed.value - conDTO.discountAmount
               })



               onMounted(() => {
                    if (route.query.quoteId) {
                         conDTO.quoteId = route.query.quoteId
                         getQuoInfo()
                    }

                    if (route.query.contractId && route.query.from) {
                         getConInfo()
                    }
               })





               return {
                    header_bg,
                    ...toRefs(conDTO),
                    conDTO,
                    areaDataArray,
                    cascaderProps,
                    checkRadio,
                    inputEvent,
                    initiate_contract,
                    validate,
                    disabledDate,
                    calToTaxed,
                    getPartNumber,
                    calcateAmountUnTaxed,
                    calcateAmountTaxed,
                    containTaxed,
                    finalAccount
               }

          }
     }
</script>

<style lang="scss">
     .initiate_contract {
          .el-input__inner {
               border: none;
          }
     }
</style>

<style lang="scss" scoped>
     input {
          border: none;
          outline: none;
          height: 32px;
     }

     .validate {
          .vBorder {
               border-color: #F30606 !important;
          }

          .error_msg {
               display: block;
               margin-top: 2px;
          }
     }

     .error_msg {
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #F30606;
          display: none;
     }

     .inquiry_header {
          position: relative;
          height: 300px;

          .header_bg {
               background-position: center center;
               width: 100%;
               height: 301px;
               position: absolute;
               left: 0;
               top: 0;
               /* margin-left: -960px; */
               background-repeat: no-repeat;
               background-size: cover;
          }
     }

     .word2 {
          margin-right: 4px;

          color: rgba(230, 0, 18, 1);
          font-size: 14px;
          white-space: nowrap;
          line-height: 14px;
     }

     .initiate_contract_z {
          background: #F5F5F5;
          padding-top: 20px;
          width: 100%;
          padding-bottom: 30px;

          .init_main {
               width: 1200px;
               background: #FFFFFF;
               margin: 0 auto;

               .box1 {
                    background-color: rgba(255, 255, 255, 1);
                    position: relative;
                    padding: 20px;

                    .block1 {
                         width: 1160px;

                         .group1 {
                              background-color: rgba(40, 95, 222, 0.1);
                              height: 40px;
                              width: 1160px;

                              .info1 {
                                   width: 66px;
                                   height: 16px;

                                   color: rgba(18, 18, 18, 1);
                                   font-size: 16px;
                                   font-family: SourceHanSansCN-Medium;
                                   text-align: left;
                                   white-space: nowrap;
                                   line-height: 16px;
                                   display: block;
                                   margin: 12px 0 0 15px;
                              }
                         }

                         .group2g {
                              margin: 20px 0 0 14px;

                              .main2 {
                                   width: 200px;
                                   height: 32px;
                                   border: 1px solid rgba(201, 201, 201, 1);
                                   padding: 0 10px;
                              }

                              .main3 {
                                   width: 191px;
                                   height: 14px;

                                   font-size: 0;
                                   font-family: SourceHanSansCN-Regular;
                                   text-align: left;
                                   white-space: nowrap;
                                   line-height: 14px;
                                   margin: 9px 0 0 21px;

                                   .txt1 {
                                        width: 191px;
                                        height: 14px;

                                        color: rgba(230, 0, 18, 1);
                                        font-size: 14px;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        white-space: nowrap;
                                        line-height: 14px;
                                   }

                                   .info3 {
                                        width: 191px;
                                        height: 14px;

                                        color: rgba(102, 102, 102, 1);
                                        font-size: 14px;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        white-space: nowrap;
                                        line-height: 14px;
                                   }

                                   .word4 {
                                        width: 191px;
                                        height: 14px;

                                        color: rgba(51, 51, 51, 1);
                                        font-size: 14px;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        white-space: nowrap;
                                        line-height: 14px;
                                   }
                              }

                              .main4 {
                                   width: 200px;
                                   height: 32px;
                                   border: 1px solid rgba(201, 201, 201, 1);
                                   margin-left: -105px;
                              }
                         }

                         .main1 {


                              font-family: SourceHanSansCN-Regular;
                              text-align: left;
                              white-space: nowrap;
                              margin-top: 9px;

                              .word2 {
                                   margin-right: 4px;

                                   color: rgba(230, 0, 18, 1);
                                   font-size: 14px;
                                   white-space: nowrap;
                                   line-height: 14px;
                              }

                              .info2 {
                                   height: 14px;

                                   color: rgba(102, 102, 102, 1);
                                   font-size: 14px;
                                   font-family: SourceHanSansCN-Regular;
                                   text-align: left;
                                   white-space: nowrap;
                                   line-height: 14px;
                              }

                              .word3 {
                                   width: 126px;
                                   height: 14px;

                                   color: rgba(51, 51, 51, 1);
                                   font-size: 14px;
                                   font-family: SourceHanSansCN-Regular;
                                   text-align: left;
                                   white-space: nowrap;
                                   line-height: 14px;
                              }
                         }

                         .group3g {
                              width: 593px;
                              margin: 20px 0 0 14px;

                              .block2 {
                                   width: 170px;
                                   height: 15px;

                                   font-size: 0;
                                   font-family: SourceHanSansCN-Regular;
                                   text-align: left;
                                   white-space: nowrap;
                                   line-height: 15px;
                                   margin-top: 8px;

                                   .word5 {
                                        width: 170px;
                                        height: 15px;

                                        color: rgba(230, 0, 18, 1);
                                        font-size: 14px;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        white-space: nowrap;
                                        line-height: 14px;
                                   }

                                   .info4 {
                                        width: 170px;
                                        height: 15px;

                                        color: rgba(102, 102, 102, 1);
                                        font-size: 14px;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        white-space: nowrap;
                                        line-height: 14px;
                                   }

                                   .word6 {
                                        width: 170px;
                                        height: 15px;

                                        color: rgba(51, 51, 51, 1);
                                        font-size: 14px;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        white-space: nowrap;
                                        line-height: 14px;
                                   }
                              }

                              .block3 {
                                   width: 507px;
                                   border: 1px solid rgba(201, 201, 201, 1);
                              }
                         }

                         .group4 {
                              width: 1146px;
                              height: 32px;
                              margin: 20px 0 0 14px;

                              .box2 {
                                   width: 228px;
                                   height: 14px;

                                   font-size: 0;
                                   font-family: SourceHanSansCN-Regular;
                                   text-align: left;
                                   white-space: nowrap;
                                   line-height: 14px;
                                   margin-top: 9px;

                                   .word7 {
                                        width: 228px;
                                        height: 14px;

                                        color: rgba(230, 0, 18, 1);
                                        font-size: 14px;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        white-space: nowrap;
                                        line-height: 14px;
                                   }

                                   .info5 {
                                        width: 228px;
                                        height: 14px;

                                        color: rgba(102, 102, 102, 1);
                                        font-size: 14px;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        white-space: nowrap;
                                        line-height: 14px;
                                   }

                                   .txt2 {
                                        width: 228px;
                                        height: 14px;

                                        color: rgba(51, 51, 51, 1);
                                        font-size: 14px;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        white-space: nowrap;
                                        line-height: 14px;
                                   }
                              }

                              .box3 {
                                   height: 34px;
                                   border: 1px solid rgba(201, 201, 201, 1);
                                   width: 1060px;
                                   padding: 0 10px;

                                   input {
                                        flex: 1;
                                   }

                                   .word8g {

                                        color: rgba(153, 153, 153, 1);
                                        font-size: 12px;

                                   }
                              }
                         }

                         .group5 {
                              background-color: rgba(40, 95, 222, 0.1);
                              height: 40px;
                              margin-top: 30px;
                              width: 1160px;

                              .word9 {
                                   width: 116px;
                                   height: 16px;

                                   color: rgba(18, 18, 18, 1);
                                   font-size: 16px;
                                   font-family: SourceHanSansCN-Medium;
                                   text-align: left;
                                   white-space: nowrap;
                                   line-height: 16px;
                                   display: block;
                                   margin: 12px 0 0 15px;
                              }
                         }

                         .group6 {
                              background-color: rgba(247, 248, 250, 1);
                              height: 45px;
                              margin-top: 19px;
                              width: 1160px;

                              .mod1g {
                                   width: 1074px;
                                   height: 16px;
                                   margin: 16px 0 0 32px;

                                   .word10 {
                                        width: 28px;
                                        height: 13px;

                                        color: rgba(51, 51, 51, 1);
                                        font-size: 14px;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        white-space: nowrap;
                                        line-height: 14px;
                                        display: block;
                                   }

                                   .txt3 {
                                        width: 57px;
                                        height: 14px;

                                        color: rgba(51, 51, 51, 1);
                                        font-size: 14px;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        white-space: nowrap;
                                        line-height: 14px;
                                        display: block;
                                        margin: 1px 0 0 51px;
                                   }

                                   .txt4 {
                                        width: 57px;
                                        height: 14px;

                                        color: rgba(51, 51, 51, 1);
                                        font-size: 14px;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        white-space: nowrap;
                                        line-height: 14px;
                                        display: block;
                                        margin: 1px 0 0 89px;
                                   }

                                   .word11 {
                                        width: 63px;
                                        height: 15px;

                                        color: rgba(51, 51, 51, 1);
                                        font-size: 14px;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        white-space: nowrap;
                                        line-height: 15px;
                                        display: block;
                                        margin: 1px 0 0 140px;
                                   }

                                   .word12 {
                                        width: 28px;
                                        height: 14px;

                                        color: rgba(51, 51, 51, 1);
                                        font-size: 14px;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        white-space: nowrap;
                                        line-height: 14px;
                                        display: block;
                                        margin: 1px 0 0 108px;
                                   }

                                   .group7 {
                                        width: 87px;
                                        height: 15px;

                                        font-size: 0;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        white-space: nowrap;
                                        line-height: 15px;
                                        margin: 1px 0 0 111px;

                                        .word13g {
                                             width: 87px;
                                             height: 15px;

                                             color: rgba(51, 51, 51, 1);
                                             font-size: 14px;
                                             font-family: SourceHanSansCN-Regular;
                                             text-align: left;
                                             white-space: nowrap;
                                             line-height: 14px;
                                        }

                                        .word14g {
                                             width: 87px;
                                             height: 15px;

                                             color: rgba(51, 51, 51, 1);
                                             font-size: 12px;
                                             font-family: SourceHanSansCN-Regular;
                                             text-align: left;
                                             white-space: nowrap;
                                             line-height: 12px;
                                        }
                                   }

                                   .info6 {
                                        width: 28px;
                                        height: 13px;

                                        color: rgba(51, 51, 51, 1);
                                        font-size: 14px;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        white-space: nowrap;
                                        line-height: 14px;
                                        display: block;
                                        margin: 1px 0 0 103px;
                                   }

                                   .info7 {
                                        width: 28px;
                                        height: 14px;

                                        color: rgba(51, 51, 51, 1);
                                        font-size: 14px;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        white-space: nowrap;
                                        line-height: 14px;
                                        display: block;
                                        margin: 1px 0 0 104px;
                                   }
                              }
                         }

                         .group8 {
                              height: 82px;
                              border: 1px solid rgba(220, 220, 220, 1);
                              width: 1160px;

                              .section1 {
                                   height: 49px;
                                   margin: 17px 0 0 30px;

                                   .word15 {
                                        width: 20px;
                                        color: rgba(51, 51, 51, 1);
                                        font-size: 12px;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: center;
                                        white-space: nowrap;
                                        line-height: 12px;
                                        margin-top: 19px;
                                        display: block;

                                   }

                                   .pic1 {
                                        width: 50px;
                                        height: 49px;
                                        margin-left: 62px;
                                   }

                                   .paragraph1 {
                                        width: 200px;
                                        color: rgba(51, 51, 51, 1);
                                        font-size: 12px;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        line-height: 14px;
                                        display: block;
                                        margin: 10px 0 0 45px;
                                        display: -webkit-box;
                                        -webkit-box-orient: vertical;
                                        -webkit-line-clamp: 2;
                                        overflow: hidden;
                                   }

                                   .word16 {
                                        width: 120px;

                                        color: rgba(51, 51, 51, 1);
                                        font-size: 12px;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        white-space: nowrap;
                                        line-height: 14px;
                                        display: block;
                                        margin: 19px 0 0 46px;
                                        display: -webkit-box;
                                        -webkit-box-orient: vertical;
                                        -webkit-line-clamp: 2;
                                        overflow: hidden;
                                   }

                                   .word17 {
                                        width: 100px;
                                        color: rgba(51, 51, 51, 1);
                                        font-size: 12px;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        white-space: nowrap;
                                        line-height: 12px;
                                        display: block;
                                        margin: 18px 0 0 47px;
                                   }

                                   .mod2g {
                                        background-color: rgba(255, 255, 255, 1);
                                        height: 36px;
                                        border: 1px solid rgba(201, 201, 201, 1);
                                        width: 99px;
                                        margin: 7px 0 0 34px;
                                        padding: 0 10px;
                                   }

                                   .mod3f {
                                        background-color: rgba(255, 255, 255, 1);
                                        height: 36px;
                                        border: 1px solid rgba(201, 201, 201, 1);
                                        width: 88px;
                                        margin: 7px 0 0 65px;
                                        padding: 0 10px;
                                   }

                                   .word18 {
                                        color: rgba(51, 51, 51, 1);
                                        font-size: 12px;
                                        margin: 20px 0 0 5px;
                                   }

                                   .word19 {
                                        color: rgba(51, 51, 51, 1);
                                        font-size: 12px;
                                        margin: 19px 0 0 48px;
                                   }

                                   .word19 {
                                        color: rgba(51, 51, 51, 1);
                                        font-size: 12px;
                                        margin: 19px 0 0 48px;
                                   }
                              }
                         }


                         .group11 {
                              background-color: rgba(247, 248, 250, 1);
                              height: 45px;
                              margin-top: 20px;
                              width: 1160px;

                              .mod4 {
                                   width: 992px;
                                   height: 15px;
                                   margin: 15px 0 0 84px;

                                   .txt9 {
                                        width: 43px;
                                        height: 14px;
                                        color: rgba(51, 51, 51, 1);
                                        font-size: 14px;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        white-space: nowrap;
                                        line-height: 14px;
                                        margin-top: 1px;
                                        display: block;
                                   }

                                   .box4 {
                                        width: 92px;
                                        height: 15px;

                                        font-size: 0;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        white-space: nowrap;
                                        line-height: 15px;
                                        margin-left: 197px;

                                        .word27 {
                                             width: 92px;
                                             height: 15px;

                                             color: rgba(51, 51, 51, 1);
                                             font-size: 14px;
                                             font-family: SourceHanSansCN-Regular;
                                             text-align: left;
                                             white-space: nowrap;
                                             line-height: 14px;
                                        }

                                        .word28 {
                                             width: 92px;
                                             height: 15px;

                                             color: rgba(51, 51, 51, 1);
                                             font-size: 12px;
                                             font-family: SourceHanSansCN-Regular;
                                             text-align: left;
                                             white-space: nowrap;
                                             line-height: 12px;
                                        }
                                   }

                                   .word29 {
                                        width: 28px;
                                        height: 13px;

                                        color: rgba(51, 51, 51, 1);
                                        font-size: 14px;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        white-space: nowrap;
                                        line-height: 14px;
                                        display: block;
                                        margin: 1px 0 0 300px;
                                   }

                                   .word30 {
                                        width: 28px;
                                        height: 14px;

                                        color: rgba(51, 51, 51, 1);
                                        font-size: 14px;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        white-space: nowrap;
                                        line-height: 14px;
                                        display: block;
                                        margin: 1px 0 0 304px;
                                   }
                              }
                         }

                         .group12 {
                              height: 82px;
                              border: 1px solid rgba(220, 220, 220, 1);
                              width: 1160px;

                              .box5 {
                                   height: 37px;
                                   margin: 23px 0 0 83px;

                                   .word31 {
                                        max-width: 120px;
                                        color: rgba(51, 51, 51, 1);
                                        font-size: 12px;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        white-space: nowrap;
                                        line-height: 12px;
                                        margin-top: 12px;
                                        display: block;
                                   }

                                   .main7 {
                                        background-color: rgba(255, 255, 255, 1);
                                        height: 36px;
                                        border: 1px solid rgba(201, 201, 201, 1);
                                        margin-left: 203px;
                                        width: 99px;
                                        padding: 0 10px;

                                        .word32 {
                                             width: 40px;

                                             color: rgba(51, 51, 51, 1);
                                             font-size: 12px;
                                             font-family: SourceHanSansCN-Regular;
                                             text-align: left;
                                             white-space: nowrap;
                                             line-height: 12px;
                                             display: block;
                                             margin: 13px 0 0 31px;
                                        }
                                   }

                                   .main8 {
                                        background-color: rgba(255, 255, 255, 1);
                                        height: 36px;
                                        border: 1px solid rgba(201, 201, 201, 1);
                                        width: 88px;
                                        margin: 1px 0 0 263px;
                                        padding: 0 10px;

                                        .info13 {
                                             width: 8px;

                                             color: rgba(51, 51, 51, 1);
                                             font-size: 14px;
                                             font-family: SourceHanSansCN-Regular;
                                             text-align: left;
                                             white-space: nowrap;
                                             line-height: 14px;
                                             display: block;
                                             margin: 12px 0 0 10px;
                                        }
                                   }

                                   .word33 {
                                        width: 11px;

                                        color: rgba(51, 51, 51, 1);
                                        font-size: 12px;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        white-space: nowrap;
                                        line-height: 12px;
                                        display: block;
                                        margin: 14px 0 0 5px;
                                   }

                                   .txt10 {
                                        width: 40px;

                                        color: rgba(51, 51, 51, 1);
                                        font-size: 12px;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        white-space: nowrap;
                                        line-height: 12px;
                                        display: block;
                                        margin: 13px 0 0 258px;
                                   }
                              }
                         }

                         .group13 {
                              width: 292px;
                              height: 16px;
                              margin: 26px 0 0 863px;

                              .box6 {
                                   width: 156px;
                                   height: 15px;

                                   font-size: 0;
                                   font-family: SourceHanSansCN-Regular;
                                   text-align: left;
                                   white-space: nowrap;
                                   line-height: 15px;
                                   margin-top: 1px;

                                   .txt11 {
                                        width: 156px;
                                        height: 15px;

                                        color: rgba(51, 51, 51, 1);
                                        font-size: 14px;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        white-space: nowrap;
                                        line-height: 15px;
                                   }

                                   .txt12 {
                                        width: 156px;
                                        height: 15px;

                                        color: rgba(243, 6, 6, 1);
                                        font-size: 14px;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        white-space: nowrap;
                                        line-height: 15px;
                                   }

                                   .info14 {
                                        width: 156px;
                                        height: 15px;

                                        color: rgba(243, 6, 6, 1);
                                        font-size: 16px;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        white-space: nowrap;
                                        line-height: 16px;
                                   }

                                   .txt13 {
                                        width: 156px;
                                        height: 15px;

                                        color: rgba(243, 6, 6, 1);
                                        font-size: 14px;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        white-space: nowrap;
                                        line-height: 15px;
                                   }
                              }

                              .word34 {
                                   width: 123px;
                                   height: 15px;

                                   color: rgba(51, 51, 51, 1);
                                   font-size: 14px;
                                   font-family: SourceHanSansCN-Regular;
                                   text-align: left;
                                   white-space: nowrap;
                                   line-height: 15px;
                                   display: block;
                              }
                         }

                         .group14 {
                              width: 168px;
                              height: 32px;
                              margin: 15px 0 0 863px;

                              .word35 {
                                   width: 130px;
                                   height: 15px;

                                   color: rgba(51, 51, 51, 1);
                                   font-size: 14px;
                                   font-family: SourceHanSansCN-Regular;
                                   text-align: left;
                                   white-space: nowrap;
                                   line-height: 15px;
                                   margin-top: 8px;
                                   display: block;
                              }

                              .block4 {
                                   width: 78px;
                                   height: 32px;
                                   border: 1px solid rgba(201, 201, 201, 1);
                                   margin-left: -40px;
                                   padding: 0 10px;
                              }
                         }

                         .group15 {
                              width: 292px;
                              height: 16px;
                              margin: 11px 0 0 863px;

                              .block5 {
                                   width: 156px;
                                   height: 15px;

                                   font-size: 0;
                                   font-family: SourceHanSansCN-Regular;
                                   text-align: left;
                                   white-space: nowrap;
                                   line-height: 15px;
                                   margin-top: 1px;

                                   .word36 {
                                        width: 156px;
                                        height: 15px;

                                        color: rgba(51, 51, 51, 1);
                                        font-size: 14px;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        white-space: nowrap;
                                        line-height: 15px;
                                   }

                                   .txt14 {
                                        width: 156px;
                                        height: 15px;

                                        color: rgba(243, 6, 6, 1);
                                        font-size: 14px;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        white-space: nowrap;
                                        line-height: 15px;
                                   }

                                   .word37 {
                                        width: 156px;
                                        height: 15px;

                                        color: rgba(243, 6, 6, 1);
                                        font-size: 16px;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        white-space: nowrap;
                                        line-height: 16px;
                                   }

                                   .word38 {
                                        width: 156px;
                                        height: 15px;

                                        color: rgba(243, 6, 6, 1);
                                        font-size: 14px;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        white-space: nowrap;
                                        line-height: 15px;
                                   }
                              }

                              .info15 {
                                   width: 123px;
                                   height: 15px;

                                   color: rgba(51, 51, 51, 1);
                                   font-size: 14px;
                                   font-family: SourceHanSansCN-Regular;
                                   text-align: left;
                                   white-space: nowrap;
                                   line-height: 15px;
                                   display: block;
                              }
                         }

                         .group16 {
                              background-color: rgba(40, 95, 222, 0.1);
                              height: 40px;
                              margin-top: 30px;
                              width: 1160px;

                              .word39 {
                                   width: 99px;
                                   height: 16px;

                                   color: rgba(18, 18, 18, 1);
                                   font-size: 16px;
                                   font-family: SourceHanSansCN-Medium;
                                   text-align: left;
                                   white-space: nowrap;
                                   line-height: 16px;
                                   display: block;
                                   margin: 12px 0 0 15px;
                              }
                         }

                         .group17 {

                              margin: 21px 0 0 15px;

                              .main9 {
                                   margin-top: 10px;

                                   .con1 {
                                        margin-bottom: 5px;
                                   }

                                   .infoBox3 {
                                        width: 121px;

                                        color: rgba(102, 102, 102, 1);
                                        font-size: 14px;
                                        font-family: SourceHanSansCN-Regular;
                                        text-align: left;
                                        line-height: 40px;
                                        white-space: nowrap;
                                   }

                                   .group18 {
                                        font-size: 14px;

                                        .group181 {
                                             border: 1px solid #c9c9c9;
                                        }
                                   }




                                   .box8 {
                                        .icon1 {
                                             width: 18px;
                                             height: 18px;
                                        }

                                        .con11 {
                                             margin-right: 31px;
                                             cursor: pointer;
                                        }


                                        .wrap2 {
                                             background-color: rgba(255, 255, 255, 1);
                                             border-radius: 50%;
                                             width: 18px;
                                             height: 18px;
                                             border: 2px solid rgba(201, 201, 201, 1);
                                        }

                                        .info16 {
                                             color: rgba(51, 51, 51, 1);
                                             font-size: 14px;
                                             margin: 0px 0 0 9px;
                                        }


                                        .wrap5 {
                                             background-color: rgba(229, 229, 229, 1);
                                             width: 68px;
                                             height: 28px;
                                             border: 1px solid rgba(201, 201, 201, 1);
                                             margin-left: 13px;
                                        }
                                   }
                              }

                         }

                         .group21 {
                              background-color: rgba(40, 95, 222, 0.1);
                              height: 40px;
                              margin-top: 30px;
                              width: 1160px;

                              .word44 {
                                   width: 66px;
                                   height: 16px;

                                   color: rgba(18, 18, 18, 1);
                                   font-size: 16px;
                                   font-family: SourceHanSansCN-Medium;
                                   text-align: left;
                                   white-space: nowrap;
                                   line-height: 16px;
                                   display: block;
                                   margin: 12px 0 0 15px;
                              }
                         }

                         .group22 {
                              width: 1145px;
                              height: 164px;
                              margin: 30px 0 0 15px;

                              .word45 {
                                   width: 63px;
                                   height: 14px;

                                   color: rgba(51, 51, 51, 1);
                                   font-size: 14px;
                                   font-family: SourceHanSansCN-Regular;
                                   text-align: left;
                                   white-space: nowrap;
                                   line-height: 14px;
                                   margin-top: 7px;
                                   display: block;
                              }

                              .layer2 {
                                   margin-left: 10px;
                                   background-color: rgba(255, 255, 255, 1);
                                   height: 164px;
                                   border: 1px solid rgba(201, 201, 201, 1);
                                   width: 1066px;
                                   padding: 10px;

                                   textarea {
                                        width: 100%;
                                        resize: none;
                                        color: rgba(153, 153, 153, 1);
                                        border: none;
                                        outline: none;
                                   }

                                   .word46 {
                                        color: rgba(153, 153, 153, 1);
                                        font-size: 14px;
                                        line-height: 14px;
                                        align-self: flex-end;
                                   }
                              }
                         }
                    }


               }


               .box11 {
                    box-shadow: 0px 2px 10px 0px rgba(131, 131, 131, 0.3);
                    background-color: rgba(255, 255, 255, 1);
                    height: 79px;
                    margin-top: 38px;

                    .box12 {
                         background-color: rgba(40, 95, 222, 1);
                         border-radius: 4px;
                         height: 36px;
                         width: 154px;
                         margin: 22px 0 0 513px;
                         cursor: pointer;

                         .txt17 {
                              width: 66px;
                              height: 16px;

                              color: rgba(255, 255, 255, 1);
                              font-size: 16px;
                              font-family: MicrosoftYaHei;
                              text-align: left;
                              white-space: nowrap;
                              line-height: 16px;
                              display: block;
                              margin: 10px 0 0 44px;
                         }
                    }
               }
          }
     }
</style>